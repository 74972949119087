.h2 {
    text-align: center;
    border-bottom: 2px black solid;
}

.solutions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.solutions div {
    width: 30%;
}

.solutions div button {
    font-size: x-large;
}

.link {
    text-decoration: none;
    color: #fff;
    font-size: larger;
    margin-right: 15px;
}

.extraButton {
    margin-bottom: 10px;
}

.additionalButtons {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.selectionList {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.selectionList div {
    width: 1300px;
}

.selectionList div button {
    width: 250px;
    margin: 4px;
}