.container {
    display: flex;
    flex-direction: column;
    width: 1350px;
    margin: auto;
}

.aboveTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
}

.paper {
    width: 25%;
    text-align: center;
    align-self: center;
    padding: 10px 0;
}